import request from '@/utils/request'
import signMd5Utils from '@/utils/encryption/signMd5Utils'

// 获取大屏分类
export const getDataVTypeSelector = () => {
  let du = '/sys/dict/getDictItems/ds_category'
  let parameter = {}
  let sign = signMd5Utils.getSign(du, parameter);
  //将签名和时间戳，添加在请求接口 Header
  let signHeader = {"X-Sign": sign,"X-TIMESTAMP": signMd5Utils.getDateTimeToString()};
  return request({
    url: du,
    method: 'GET',
    parameter:parameter,
    headers: signHeader
  })
}

// 添加大屏
export const createDataV = data => {
  return request({
    url: '/rpt/ds',
    method: 'POST',
    data
  })
}

// 修改大屏
export const updateDataV = (data, id) => {
  data.id = id
  return request({
    url: `/rpt/ds`,
    method: 'PUT',
    data
  })
}

// 获取大屏信息
export const getDataVInfo = id => {
  return request({
    url: `/rpt/ds/${id}`,
    method: 'GET',
  })
}

// 保存截图
export const savaScreenShot = data => {
  data.biz = 'ds'
  return request({
    url: '/sys/common/upload',
    method: 'POST',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  })
}


// 获取地图下拉框
export const getMapSeletor = () => {
  return request({
    url: '/rpt/map',
    method: 'GET'
  })
}

// 数据接口下拉列表
export const getDataInterfaceSelector = () => {
  return request({
    url: '/rpt/di/list',
    method: 'GET'
  })
}

// 获取数据接口数据
export const getDataInterfaceResponse = id => {
  return request({
    url: `/online/cgreport/api/getColumnsAndData/${id}?pageNo=1&pageSize=1000`,
    method: 'GET'
  })
}

// 获取图片列表
export const getImgList = type => {
  return request({
    url: `/rpt/material?type=${type}`,
    method: 'GET',
  })
}
